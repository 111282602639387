// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { common as wp } from '@hcd-caravanhealth/pkg-wptypes';
import { TrackFeatureConstants } from '../app/shared/models/module-constants';
import { AppIds, EnvironmentType, IEnvironment } from './environmentTypeDef';

export const environment: IEnvironment = {
  production: true,
  wpApiUrl: 'https://api.caravan.health/wpapi/2',
  ocpApimSubscriptionKey: 'f9c400517fb1445fb082dab2b2795a07',
  chEndPoint: 'https://id.caravanhealth.com/connect/userinfo',

  // This is the app id in B2C
  // Need separate app ids for dev, qa, demo and prod
  // Continue using dev for localhost
  // This app id is also used in the Wellpepper api to determine the data partition for patients
  authClientId: AppIds.prod,
  environmentId: EnvironmentType.prod,
  // Different endpoint for prod IDS - there will be a different custom policy.
  // Continue using the same B2C directory instance
  authWellknownEndpoint:
    'https://chprodb2c.b2clogin.com/chprodb2c.onmicrosoft.com/v2.0/.well-known/openid-configuration?p=B2C_1A_signup_signinCaravanHealthProd',
  stsServer: 'https://login.microsoftonline.com/chprodb2c.onmicrosoft.com/v2.0',

  authConfigScope:
    'openid https://chprodb2c.onmicrosoft.com/coachApi/demo.write https://chprodb2c.onmicrosoft.com/coachApi/demo.read',
  patientIdentityProvider:
    wp.IdentifierProviders.CaravanHealthPatientInHierarchyNodeProdEnvironment,

  // Currently pointing directly to app service
  // Want it to be api.qa.caravan.health
  coachApiUrl: 'https://api.caravan.health/coach',
  prizmApiUrl: 'https://prizmapi.caravan.health',
  careOrchApiUrl: 'https://api.caravan.health/careorchservice',
  // Would like this to also be api.qa.caravan.health/track
  // Have API management service deal with routing to different services
  trackApiUrl: 'https://api.caravan.health/track',
  userDetailApiUrl: 'https://api.caravan.health/access',
  apiBaseUrl: 'https://api.caravan.health',

  blobStorageUrl: 'https://caravancoachdocrepo.blob.core.windows.net/documents',
  maskSensitiveData: false,
  useHashStrategy: false,
  idleStart: 1500,
  idleTimeout: 300,
  idlePing: 120,
  mixpanel: {
    projectId: '53e070d656ca91e15fdb704249eba29e',
    debug: false,
    pageViews: true
  },
  appInsights: {
    connectionString: 'InstrumentationKey=904ea4f0-5d02-43fd-8acf-b35624921bc1',
  },
  displayRiskLevel: true,
  serverEventsEndpoint: 'https://coachsignalr-prod-as.azurewebsites.net',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
